import React, { useState, useEffect } from 'react';
import { Footer, Header, Container, GridContainer, Quote, SEO } from '../components';
import BlockContent from '@sanity/block-content-to-react';
import YouTube from 'react-youtube';
import getYouTubeID from 'get-youtube-id';
//
import { serializers } from '../utils/blockContent';
import cssModule from './single-news.module.scss';

const SingleNews = props => {
  const options = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const TextSection = props => {
    const [textSection, setTextSection] = useState('');

    useEffect(() => {
      setTextSection(props);
    }, []);

    return (
      <div>
        {textSection && textSection.description && (
          <BlockContent blocks={textSection.description} serializers={serializers} />
        )}
      </div>
    );
  };

  const renderSections = (sections, slug) => {
    return sections.map(section => {
      switch (section._type) {
        case 'textBlock':
          return (
            <Container className={cssModule.textBlock}>
              <GridContainer large="auto / .5fr" xlarge="auto / .32fr" justifyContent="center">
                <TextSection {...section} />
              </GridContainer>
            </Container>
          );
        case 'image':
          return (
            <Container className={cssModule.dividerMargin}>
              <GridContainer
                className={cssModule.imageGrid}
                small="auto / 1fr"
                large="auto / .7fr"
                xlarge="auto / .5fr"
                justifyContent="center"
              >
                <div>
                  <img src={section.image} alt="nesto" />
                </div>
              </GridContainer>
            </Container>
          );
        case 'youtube':
          return (
            <Container className={cssModule.dividerMargin}>
              <GridContainer
                className={cssModule.videoGrid}
                small="auto / 1fr"
                large="auto / .7fr"
                xlarge="auto / .5fr"
                justifyContent="center"
              >
                <div className="video-holder no-b-p">
                  <YouTube videoId={getYouTubeID(section.url)} opts={options} />
                </div>
              </GridContainer>
            </Container>
          );
        case 'quote':
          return (
            <Container className={cssModule.quote}>
              <GridContainer
                small="auto / 1fr, 0 32px"
                tablet="auto / 1fr 1fr"
                large="auto / 2.2fr 4fr 1fr 2fr"
                xlarge="auto / 4.5fr 3.6fr 1fr 2fr 2fr"
                justifyContent="flex-start"
              >
                <Quote
                  author={section.author}
                  publisher={section.publisher}
                  quote={section.quote}
                />
              </GridContainer>
            </Container>
          );
        default:
          return null;
      }
    });
  };

  const { i18n_lang: lang, title, metadata, sections, translationPath, image } = props.pageContext;

  console.log({ props });
  return (
    <>
      <div className="single-news-page">
        <SEO {...metadata} />
        <Header {...{ lang, heading: title }} lang={lang} translationPath={translationPath} />
        <div className={`${cssModule.singleNews} single-news`}>
          {/* TODO: feature image <img src={image} /> */}
          <div className="wyswyg">{renderSections(sections, 'slug')}</div>
        </div>
      </div>
      <Footer lang={lang} />
    </>
  );
};

export default SingleNews;
